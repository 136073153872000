<template>

	<div>

		<!-- Filters -->
		<verify-list-filters
			:year-filter.sync="yearFilter"
			:month-filter.sync="monthFilter"
            :conditions-filter.sync="conditionsFilter"
        />
		
		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0">

			<div class="m-2">

				<!-- Table Top -->
				<b-row>

					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>
													   
					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto">
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block"
								placeholder="Buscar proveedor ..."
								trim
							/>
						</div>
					</b-col>

				</b-row>

			</div>

			<b-table
				ref="refVerifyListTable"
				class="position-relative table-verify"
				:style="'height: inherit'"
				:items="fetchVerify"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>

				<template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mb-1"></b-spinner>
                        <div><b>CARGANDO</b></div>
                    </div>
                </template>

				<template #head(start_date)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(verified_email_sent_count)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(start_date)="data">
					<b-badge variant="light-info">{{ data.item.month }}</b-badge>
                </template>

				<template #cell(proveedor_name)="data">
					{{ data.item.proveedor_name }}
				</template>

				<template #cell(state_name)="data">
                    <b-badge :variant="data.item.state_color">
                        {{ data.item.state_name }}
                    </b-badge>
                </template>

                <template #cell(condition_name)="data">
                    <b-badge :variant="data.item.condition_color">
                        {{ data.item.condition_name }}
                    </b-badge>
                </template>
				
				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL">

						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item 
                            v-if="data.item.report_path"
                            @click=viewGuaranteeReport(data.item.id)
                        >                             
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar informe fondo de garantía
                            </span>
                        </b-dropdown-item>

						<b-dropdown-item @click="openModalVerifyForm(data.item)">
							<feather-icon icon="EyeIcon" />
							<span class="align-middle ml-50">Verificar</span>
						</b-dropdown-item>

						<b-dropdown-item
                            v-if="data.item.condition_id !== 1"
							@click="openModalSendEmailToSeveral(data.item)"
                        >
                            <feather-icon icon="SendIcon" />
                            <span class="align-middle ml-50">Mandar verificación</span>
                        </b-dropdown-item>

					</b-dropdown>
				</template>

			</b-table>

			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination
							v-model="currentPage"
							:total-rows="totalVerify"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item">

							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>

		<BButtonVerifyForm 
			ref="verifyForm" 
			:data="data"
			@refresh-data="refetchData"
		/>

		<BButtonSendEmailToSeveral 
			ref="sendEmailToSeveral" 
			:data="data2"
			@refresh-data="refetchData"
		/>
		
	</div>

</template>

<script>

	import BButtonSendEmailToSeveral from '@core/components/b-button-send-email-to-several/BButtonSendEmailToSeveral.vue';
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import BButtonVerifyForm from '@core/components/b-button-verify-form/BButtonVerifyForm.vue';
	import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
	import { useToast } from 'vue-toastification/composition';
	import VerifyListFilters from './VerifyListFilters.vue';
	import verifyStoreModule from '../verifyStoreModule';
	import useVerifyList from './useVerifyList';
	import { v4 as uuidv4 } from 'uuid';
	import vSelect from 'vue-select';
	import store from '@/store';
	import moment from 'moment';
	import axios from '@axios';
	
	export default {
		components: {
			BButtonSendEmailToSeveral,
			VerifyListFilters,
			BButtonVerifyForm,

			vSelect
		},
		data() {
            return {
                moment
            }
        },
		setup() {

			// USE TOAST
			const toast = useToast();

			// REFS
			const verifyForm = ref(null);
			const sendEmailToSeveral = ref(null); 
            const options = ref({});
			const data2 = ref({});
            const data = ref({});

			const VERIFY_APP_STORE_MODULE_NAME = 'app-verify';

			// REGISTER MODULE
			if (!store.hasModule(VERIFY_APP_STORE_MODULE_NAME)) store.registerModule(VERIFY_APP_STORE_MODULE_NAME, verifyStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(VERIFY_APP_STORE_MODULE_NAME)) store.unregisterModule(VERIFY_APP_STORE_MODULE_NAME);
			});

			// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( () => {
                getSelectFilters();
            });

            const getSelectFilters = async () => {
                const { data } = await axios.get('/verify/filters');
                options.value = data.data;
            };

			const openModalSendEmailToSeveral = (object) => {
				data2.value = {
					code: uuidv4(),
					form_id: object.id,
					title: 'MANDAR VERIFICACIÓN',
					mode: 'sendVerification',
                    options: {
                        revisers: options.value.revisers
                    }
				}

				sendEmailToSeveral.value.modal.show();
			};

			const viewGuaranteeReport = (id) => {

				store.dispatch('app-verify/viewGuaranteeReport', { id })
					.then( response => {

						window.open(response.data.url, '_blank');

					})
					.catch( () => {

						toast({
							component: ToastificationContent,
							props: {
								title: 'Error al descargar el informe de fondo de garantía.',
								icon: 'AlertTriangleIcon',
								variant: 'danger'
							}
						});

					});

			};

			const openModalVerifyForm = (object) => {
				data.value = {
					form_id: object.id,
					title: `EVALUAR - ${ object.proveedor_name } (${ object.month })`,
					approved: object.condition_id,
					observations: object.observations
				}

				verifyForm.value.modal.show();
			};
			
			const {
				fetchVerify,
				tableColumns,
				perPage,
				currentPage,
				totalVerify,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refVerifyListTable,
				refetchData,

				// EXTRA FILTERS
				yearFilter,
				monthFilter,
                conditionsFilter
			} = useVerifyList();
			
			return {
				fetchVerify,
				tableColumns,
				perPage,
				currentPage,
				totalVerify,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refVerifyListTable,
				refetchData,

				// REFS
				sendEmailToSeveral,
				verifyForm,
				data2,
				data,

				// EXTRA FILTERS
				yearFilter,
				monthFilter,
                conditionsFilter,

				// METHODS
				openModalSendEmailToSeveral,
				viewGuaranteeReport,
				openModalVerifyForm
			}
		}
	}

</script>

<style lang="scss" scoped>

	.media {
        align-items: center;
    }

	.per-page-selector {
		width: 90px;
	}    

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
</style>