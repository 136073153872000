import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchVerify(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/verify', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        viewGuaranteeReport(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/verify/${id}/view-guarantee-report`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateVerification(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/verify/${id}/update`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}