<template>
    <b-card no-body>
        <b-card-header>
            <h5 class="mb-0 text-white"><b>FILTROS</b></h5>
        </b-card-header>
        <b-card-body class="pt-2">
            <b-row>

                <b-col 
                    cols="12" 
                    md="4" 
                    class="mb-2"
                >
                    <label for="year"><strong>AÑO</strong></label>
                    <v-select
                        id="year"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        v-model="formData.year"
                        :options="options.years"
                        :clearable="false"
                        class="w-100"
                        @input="inputYearVal()"
                    />
                </b-col>

                <b-col 
                    cols="12" 
                    md="4" 
                    class="mb-2"
                >
                    <label for="month"><strong>MES</strong></label>
                    <v-select
                        id="month"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        v-model="formData.month"
                        :options="options.months"
                        :clearable="false"
                        class="w-100"
                        @input="inputMonthVal()"
                    />
                </b-col>

                <b-col
                    cols="12"
                    md="4"
                    class="mb-2"
                >
                    <label for="conditions"><strong>CONDICIÓN(ES)</strong></label>
                    <v-select
                        id="conditions"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        v-model="formData.conditionsArray"
                        :options="options.conditions"
                        class="w-100"
                        @input="inputConditionsVal()"
                    />
                </b-col>

            </b-row>

        </b-card-body>
    </b-card>
</template>

<script>

    import { ref, onBeforeMount } from '@vue/composition-api';
    import vSelect from 'vue-select';
    import moment from 'moment';
    import axios from '@axios';

    export default {
        components: {
            vSelect
        },
        setup(props, { emit }) {

            // REFS
            const options = ref({});

            const formData = ref({
                year: null,
                month: null,
                conditionsArray: []
            });

            // CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( async () => {
                await getSelectFilters();
                setData();
            });

            const getSelectFilters = async () => {
                const { data } = await axios.get('/verify/filters');
                options.value = data.data;
            };

            const setData = () => {

                const currentYear = parseInt(moment().subtract(1, 'months').format('YYYY'));
                const currentMonth = parseInt(moment().subtract(1, 'months').format('MM'));

                formData.value.year = options.value.years.find(year => year.id === currentYear);
                formData.value.month = options.value.months.find(month => month.id === currentMonth);

            };

            const inputYearVal = () => {
                emit('update:yearFilter', formData.value.year.id);
            };

            const inputMonthVal = () => {
                emit('update:monthFilter', formData.value.month.id);
            };

            const inputConditionsVal = () => {
                if (formData.value.conditionsArray)
                {
                    const array = formData.value.conditionsArray.map(item => item.value);
                    emit('update:conditionsFilter', array.join(','));

                    return array;
                }

                return null;
            };

            return {
                // DATA
                formData,
                options,

                // METHODS
                inputYearVal,
                inputMonthVal,
                inputConditionsVal
            }
        }
    }

</script>

<style lang="scss" scoped>

    .card-header {
        background-color: var(--primary);
        padding: 1rem 1.5rem;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>