<template>
    <b-modal
        ref="modal"
        centered
        :title="title"
        modal-class="modal-primary"
        no-close-on-backdrop
        no-close-on-esc
    >
        <b-row>
            
            <b-col cols="12" class="text-center">
                <b-row align-h="around" class="my-2">
                    <b-col cols="4">
                        <b-form-radio
                            v-model="approved"
                            value="2"
                            :checked="approved == 2 ? true : false" 
                            class="custom-control-success"
                        >
                            APROBAR
                        </b-form-radio>
                    </b-col>
                    <b-col cols="4">
                        <b-form-radio
                            v-model="approved"
                            value="3"
                            :checked="approved == 3 ? true : false" 
                            class="custom-control-danger"
                        >
                            DESAPROBAR
                        </b-form-radio>
                    </b-col>
                </b-row>			
            </b-col>

            <b-col cols="12">
                <b-form-textarea
                    id="textarea"            
                    placeholder="Ingrese comentarios ..."
                    v-model="observations"
                    class="my-2"
                    rows="5"
                >                
                </b-form-textarea>
            </b-col>

        </b-row>

        <template #modal-footer="{ cancel }">
            <b-button-loading 
                text="ACTUALIZAR" 
                :processing="processing"
                @process-action="updateVerification">
            </b-button-loading>

            <b-button variant="outline-danger" @click="cancel()">
                CERRAR
            </b-button>
        </template>
        
    </b-modal>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { useToast } from 'vue-toastification/composition';
    import { ref, watch } from '@vue/composition-api';
    import store from '@/store';

    export default {
        components: {
            BButtonLoading
        },
        props: {
            data: {
                type: Object,
                required: true,
                default: {}
            }
        },
        setup(props, { emit }) {

            // USE TOAST
			const toast = useToast();

            // REFS
            const observations = ref(null);
            const processing = ref(false);
            const approved = ref(null);
            const modal = ref(null);
            const form = ref(null);
            const title = ref('');

            // WATCH
            watch( () => props.data, (newValue) => {

                processing.value = false;

                title.value = newValue.title;
                form.value = newValue.form_id;
                approved.value = newValue.approved;
                observations.value = newValue.observations;

            });

            // METHODS
            const updateVerification = () => {

                processing.value = true;

                const id = form.value;
                const payload = { approved: approved.value, observations: observations.value };

                store.dispatch('app-verify/updateVerification', { id, payload })
					.then( response => {

						emit('refresh-data');

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                        modal.value.hide();

					})
					.catch( (err) => {

						let message = err.response.data.message ? err.response.data.message : 'Error al actualizar la verificación del formulario.';
                        
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });

					})
                    .finally( () => processing.value = false);

            };

            return {
                // REFS
                observations,
                processing,
                approved,
                modal,
                title,

                // METHODS
                updateVerification
            }
        }
    }

</script>