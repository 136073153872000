import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';

export default function useVerifyList() {

    // Use toast
    const toast = useToast();

    const refVerifyListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'start_date', sortable: true, label: 'MES/AÑO <br> EVALUACIÓN' },
        { key: 'proveedor_name', sortable: true, label: 'PROVEEDOR' },
        { key: 'proveedor_ruc', sortable: true, label: 'RUC' },
        { key: 'rubro', sortable: true, label: 'RUBRO' },
        { key: 'state_name', sortable: true, label: 'ESTADO' },
        { key: 'condition_name', sortable: true, label: 'CONDICIÓN' },
        { key: 'verified_email_sent_count', sortable: true, label: 'CANTIDAD DE <br> CORREOS ENVIADOS' },
        { key: 'actions', sortable: false, label: 'OPCIONES' }
    ];

    const perPage = ref(10);
    const totalVerify = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [5, 10, 15, 20];
    const searchQuery = ref('');
    const sortBy = ref('proveedor_name');
    const isSortDirDesc = ref(false);

    const yearFilter = ref(null);
    const monthFilter = ref(null);
    const conditionsFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refVerifyListTable.value ? refVerifyListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalVerify.value,
        }
    });

    const refetchData = () => {
        refVerifyListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, yearFilter, monthFilter, conditionsFilter], () => {
        refetchData();
    });

    const fetchVerify = (ctx, callback) => {
        store
            .dispatch('app-verify/fetchVerify', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,

                year: yearFilter.value,
                month: monthFilter.value,
                conditions: conditionsFilter.value
            })
            .then(response => {
                const { verify, total } = response.data;
                callback(verify);
                totalVerify.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de formularios',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            });
    };

    return {
        fetchVerify,
        tableColumns,
        perPage,
        currentPage,
        totalVerify,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refVerifyListTable,

        refetchData,

        // Extra Filters
        yearFilter,
        monthFilter,
        conditionsFilter
    }
}