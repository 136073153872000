<template>
    <b-modal
        ref="modal"
        centered
        :title="title"
        modal-class="modal-primary"
        no-close-on-backdrop
        no-close-on-esc
    >   
        <validation-observer ref="refForm">

            <b-row>
                
                <b-col xs="12" class="mb-1" v-if="mode === 'sendReport'">
                    <label for="checker">
                        <strong>VERIFICADOR</strong>
                    </label>
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="verificador"
                    >
                        <v-select 
                            id="checker"
                            v-model="checker"
                            :state="errors.length > 0 ? false : null"
                            :options="options.checkers" 
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>

                <b-col xs="12" class="mb-1" v-if="mode === 'sendVerification'">
                    <label for="reviser">
                        <strong>REVISOR</strong>
                    </label>
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="revisor"
                    >
                        <v-select 
                            id="reviser"
                            v-model="reviser"
                            :state="errors.length > 0 ? false : null"
                            :options="options.revisers" 
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>

            </b-row>

        </validation-observer>

        <template #modal-footer="{ cancel }">

            <!-- BUTTON SUBMIT -->
            <b-button-loading 
                text="ENVIAR"
                :processing="processing"
                @process-action="onSubmit">
            </b-button-loading>

            <b-button variant="outline-danger" @click="cancel()">
                CERRAR
            </b-button>

        </template>
        
    </b-modal>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import sendEmailToSeveralStoreModule from './sendEmailToSeveralStoreModule';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { ref, watch, onUnmounted } from '@vue/composition-api';
    import { useToast } from 'vue-toastification/composition';
    import { required } from '@validations';
    import vSelect from 'vue-select';
    import store from '@/store';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading,
            required,
            vSelect
        },
        props: {
            data: {
                type: Object,
                required: true,
                default: {}
            }
        },
        setup(props, { emit }) {

            // USE TOAST
			const toast = useToast();

            // REFS
            const processing = ref(false);
            const refForm = ref(null);

            const checker = ref(null);
            const reviser = ref(null);
            const options = ref({});
            const modal = ref(null);
            const form = ref(null);
            const mode = ref(null);
            const title = ref('');

            const SEND_EMAIL_TO_SEVERAL_APP_STORE_MODULE_NAME = 'app-send-email-to-several';

            // REGISTER MODULE
            if (!store.hasModule(SEND_EMAIL_TO_SEVERAL_APP_STORE_MODULE_NAME)) store.registerModule(SEND_EMAIL_TO_SEVERAL_APP_STORE_MODULE_NAME, sendEmailToSeveralStoreModule);

            // UNREGISTER ON LEAVE
            onUnmounted(() => {
                if (store.hasModule(SEND_EMAIL_TO_SEVERAL_APP_STORE_MODULE_NAME)) store.unregisterModule(SEND_EMAIL_TO_SEVERAL_APP_STORE_MODULE_NAME);
            });

            // WATCH
            watch( () => props.data, (newValue) => {

                processing.value = false;

                mode.value = null;
                checker.value = null;
                reviser.value = null;

                title.value = newValue.title;
                mode.value = newValue.mode;
                form.value = newValue.form_id;
                options.value = newValue.options;

                if (refForm.value) refForm.value.reset();

            });

            const onSubmit = async () => {

                const isValid = await refForm.value.validate();

                if (isValid)
                {
                    processing.value = true;

                    const id = form.value;
                    const payload = (mode.value === 'sendReport' ? { checker: checker.value.value } : { reviser: reviser.value.value });

                    const storeDispatch = (mode.value === 'sendReport' ? 'sendEmailWithReportAttached' : 'sendEmailWithVerificationResponse');

                    store.dispatch(`app-send-email-to-several/${ storeDispatch }`, { id, payload })
                        .then( response => {

                            emit('refresh-data');

                            toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'CheckIcon',
                                    variant: 'success'
                                }
                            });

                            modal.value.hide();

                        })
                        .catch( (err) => {

                            let message = err.response.data.message ? err.response.data.message : (mode.value === 'sendReport' ? 'Error al enviar el informe de fondo de garantía.' : 'Error al enviar la respuesta de la verificación.');

                            toast({
                                component: ToastificationContent,
                                props: {
                                    title: message,
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            });

                        })
                        .finally( () => processing.value = false);
                }

            };

            return {
                // REFS
                processing,
                refForm,
                checker,
                reviser,
                options,
                modal,
                title,
                mode,

                // METHODS
                onSubmit
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>